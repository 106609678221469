export const APIURL = "https://api.flyheadmedia.com/api";
export const APIURLX = "https://api.flyheadmedia.com/api/xpediagames";
export const UPLOADSDATAS = "https://api.flyheadmedia.com/public/uploads";

// export const APIURL = "http://localhost:8000/api";
// export const APIURLX = "http://localhost:8000/api/xpediagames";
// export const UPLOADSDATAS = "http://localhost:8000/public/uploads";

// export const user = JSON.parse(localStorage.getItem("admin-user"));

export function GETTOKEN() {
  let user = JSON.parse(localStorage.getItem("admin-user"));
  if (user) {
    return user.token;
  } else {
    return "73j93js857fh589djsjaksj384940DJ34849Djjd";
  }
}

const storage = JSON.parse(localStorage.getItem("admin-user"));
export const Auth_user = storage?.role;
