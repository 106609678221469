import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { GETTOKEN } from "../../../Secure/Helper";
import { APIURLX } from "../../../Secure/Helper";

export const Xcontact = createApi({
  reducerPath: "Xcontact",
  baseQuery: fetchBaseQuery({ baseUrl: `${APIURLX}` }),
  endpoints: (builder) => ({
    getXContact: builder.query({
      query: () => {
        // console.log("body data :", body);
        return {
          url: "/contact",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteXContact: builder.mutation({
      query: (id) => {
        console.log("body data :", id);
        return {
          url: `/contact/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const { useGetXContactQuery, useDeleteXContactMutation } = Xcontact;
