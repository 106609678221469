import React, { useState } from "react";
import { toast } from "react-toastify";
import { useCreateXStoreMutation } from "../../../App/Slice/XpediaGames/xstoreSlice";

function AddStore() {
  const [name, setName] = useState();
  const [link, setLink] = useState();
  const [file, setFile] = useState();

  const [createStore] = useCreateXStoreMutation();

  const AddStoreFormHandler = async (e) => {
    e.preventDefault();
    // alert("OK");
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("link", link);
      formData.append("file", file);
      const response = await createStore(formData);
      const mes = response?.data?.message;
      const mess = response?.data?.status;
      if (mess === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.href = "/xpediagame/store";
        }, 1000);
      } else {
        toast.error(`${mess}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.log("create team error : ", error);
    }
  };

  return (
    <>
      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Add New Store
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <form
                action=""
                className="form-data"
                onSubmit={AddStoreFormHandler}
              >
                <div className="input-fields">
                  <label>Store Name</label>
                  <input
                    type="text"
                    placeholder="Ram Kumar"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Store Link</label>
                  <input
                    type="text"
                    placeholder="https://mediaxpedia.com/link"
                    onChange={(e) => setLink(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Uploads Campaign Logo</label>
                  <input
                    type="file"
                    onChange={(e) => setFile(e.target.files[0])}
                  />
                </div>
                <div className="input-fields">
                  <button className="btn btn-light" type="submit">
                    SUBMIT
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AddStore;
