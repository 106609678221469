import React from "react";
import TopNavbar from "../Components/Mediaxpedia/TopNavbar";
import Nav from "../Components/Mediaxpedia/Nav";
import { FiChevronRight, FiTrash2 } from "react-icons/fi";
import { Link } from "react-router-dom";
import {
  useDeleteUsersMutation,
  useGetUsersQuery,
} from "../App/Slice/UserSlice";
import { toast } from "react-toastify";
import { APIURL, GETTOKEN } from "../Secure/Helper";

function AdminUsers() {
  const { data } = useGetUsersQuery();
  const all_user = data?.users;

  const [deleteUsers] = useDeleteUsersMutation();

  const DeleteHandler = async (id) => {
    if (id) {
      const result = await deleteUsers(id);
      console.log(result);
      let message = result?.data?.message;
      let mes = result?.data?.status;
      if (mes === "success") {
        toast.success(`${message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.href = "/admin-users";
        }, 1000);
      } else {
        toast.error(`${message}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      return;
    }
  };

  // const verifyHandler = (id, email, name) => {
  //   alert(`send mail verify link on ${email}`);
  //   try {
  //     const data = { _id: id, email: email, name: name };
  //     fetch(`${APIURL}/user/email-send-verify/${id}`, {
  //       method: "POST",
  //       headers: {
  //         Authorization: `Bearer ${GETTOKEN()}`,
  //         "Content-Type": "application/json",
  //       },
  //       body: JSON.stringify(data),
  //     })
  //       .then((res) => res.json())
  //       .then((result) => {
  //         console.log("email send", result);
  //         if (!result) {
  //           toast.error(result.message, {
  //             position: "top-center",
  //             autoClose: 1000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "colored",
  //           });
  //         } else {
  //           toast.success(result.message, {
  //             position: "top-center",
  //             autoClose: 1000,
  //             hideProgressBar: false,
  //             closeOnClick: true,
  //             pauseOnHover: true,
  //             draggable: true,
  //             progress: undefined,
  //             theme: "colored",
  //           });
  //         }
  //       });
  //   } catch (error) {
  //     toast.error(error.message, {
  //       position: "top-center",
  //       autoClose: 1000,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   }
  // };

  return (
    <>
      <div className="container">
        <div className="row">
          <TopNavbar />
        </div>
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 bg-light p-0">
            <Nav />
          </div>
          <div className="col-lg-10 col-md-10 col-12 scroll-section">
            <div className="heading-section mt-3 pannel-btn d-flex align-items-center justify-content-between">
              <h5 className="mb-0 fw-bold">ADMIN USERS</h5>
            </div>
            <table className="table mt-3">
              <thead>
                <tr className="bg-light">
                  <th scope="col">#</th>
                  <th scope="col">User Code</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Mail Verified</th>
                  <th scope="col">Role</th>
                  <th scope="col">Date</th>
                  <th scope="col">Admin</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {all_user?.map((val, ind) => {
                  const { _id, name, email, email_verified, role, createdAt } =
                    val;
                  return (
                    <tr key={ind}>
                      <th scope="row">
                        <FiChevronRight />
                      </th>
                      <td className="bg-light">{_id}</td>
                      <td>{name}</td>
                      <td>{email}</td>

                      {email_verified === "true" ? (
                        <td className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-success verify-btn"
                            disabled
                          >
                            Verified
                          </button>
                        </td>
                      ) : (
                        <td className="d-flex align-items-center justify-content-center">
                          <button
                            className="btn btn-danger verify-btn"
                            disabled
                          >
                            Not Verified
                          </button>
                        </td>
                      )}

                      <td>{role}</td>
                      <td>{createdAt?.slice(0, 10)}</td>
                      <td>
                        <Link
                          to=""
                          type="button"
                          className="btn btn-dark"
                          title="view admin users"
                        >
                          View
                        </Link>
                      </td>
                      <td
                        title={`jitu user Delete!`}
                        type="button"
                        className="career_delete bg-dark"
                        onClick={() => DeleteHandler(_id)}
                      >
                        <FiTrash2 />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AdminUsers;
