import React from "react";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Protected from "./Secure/Protected";

import { Route, Routes, json } from "react-router-dom";
import Home from "./Pages/Home";
import SignIn from "./Profile/SignIn";
import SignUp from "./Profile/SignUp";
import ForgotPassword from "./Profile/ForgotPassword";
import PageNotFound from "./Secure/PageNotFound";
import Media_Xpedia from "./Pages/Mediaxpedia/Media_Xpedia";
import AllCareer from "./Views/Mediaxpedia/Career/AllCareer";
import AddCareer from "./Views/Mediaxpedia/Career/AddCareer";
import AllContact from "./Views/Mediaxpedia/Contact/AllContact";
import AllTeam from "./Views/Mediaxpedia/Team/AllTeam";
import AllLife from "./Views/Mediaxpedia/Life/AllLife";
import AdminUsers from "./Profile/AdminUsers";
import VerifyEmail from "./Profile/VerifyEmail";
import XpediaGames from "./Pages/XpediaGames/XpediaGames";
import Banner from "./Views/Xpediagames/Banner/Banner";
import XAllContact from "./Views/Xpediagames/Contact/AllContact";
import AllGames from "./Views/Xpediagames/Games/Games";
import AllStore from "./Views/Xpediagames/Store/AllStore";

function App() {
  
  return (
    <>
      <Routes>
        <Route path="/" element={<Protected ProtectRoute={Home} />} />
        <Route path="/signin" element={<Protected ProtectRoute={SignIn} />} />
        <Route path="/signup" element={<Protected ProtectRoute={SignUp} />} />
        {/* <Route path="/signup" element={<SignUp />} /> */}
        <Route
          path="/forgot-password"
          element={<Protected ProtectRoute={ForgotPassword} />}
        />

        {/* ----------------Media-xpedia----------------------------- */}
        <Route
          path="/mediaxpedia-details"
          element={<Protected ProtectRoute={Media_Xpedia} />}
        />

        <Route
          path="/career"
          element={<Protected ProtectRoute={AllCareer} />}
        />
        <Route
          path="/add-career"
          element={<Protected ProtectRoute={AddCareer} />}
        />

        <Route
          path="/contact"
          element={<Protected ProtectRoute={AllContact} />}
        />

        <Route
          path="/our-team"
          element={<Protected ProtectRoute={AllTeam} />}
        />

        <Route
          path="/mediaxpedia-life"
          element={<Protected ProtectRoute={AllLife} />}
        />

        {/* -----------------xpedia-games----------------------------- */}
        <Route
          path="/xpediagames-details"
          element={<Protected ProtectRoute={XpediaGames} />}
        />

        <Route path="/xpediagame/banner" element={<Protected ProtectRoute={Banner} />} />

        <Route path="/xpediagame/contact" element={<Protected ProtectRoute={XAllContact} />} />

        <Route path="/xpediagame/games" element={<Protected ProtectRoute={AllGames} />} />

        <Route path="/xpediagame/store" element={<Protected ProtectRoute={AllStore} />} />

        <Route
          path="/admin-users"
          element={<Protected ProtectRoute={AdminUsers} />}
        />
        <Route path="/email-verify" element={<VerifyEmail />} />

        <Route path="/404-error" element={<PageNotFound />} />
        <Route path="/*" element={<PageNotFound />} />
      </Routes>

      <ToastContainer />
    </>
  );
}

export default App;
