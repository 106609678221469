import React from "react";
import Nav from "../../../Components/Xpediagames/Nav";
import TopNavbar from "../../../Components/Xpediagames/TopNavbar";
import { Link } from "react-router-dom";
import { FiTrash2 } from "react-icons/fi";
import { UPLOADSDATAS } from "../../../Secure/Helper";
import { toast } from "react-toastify";
import {
  useDeleteXStoreMutation,
  useGetXStoreQuery,
} from "../../../App/Slice/XpediaGames/xstoreSlice";
import AddStore from "./AddStore";

function Banner() {
  const { data } = useGetXStoreQuery();
  const allData = data?.store;
  // console.log("banner :", allData);

  const [deleteBanner] = useDeleteXStoreMutation();

  const DeleteHandler = async (id) => {
    // alert(id);
    try {
      const response = await deleteBanner(id);
      const mes = response?.data?.status;
      if (mes === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.href = "/xpediagame/store";
        }, 1000);
      } else {
        toast.error(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } catch (error) {
      console.log("create team error : ", error);
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <TopNavbar />
        </div>
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 bg-light p-0">
            <Nav />
          </div>
          <div className="col-lg-10 col-md-10 col-12 scroll-section">
            <div className="heading-section mt-3 pannel-btn d-flex align-items-center justify-content-between mb-5">
              <h5 className="mb-0 fw-bold">ALL XPEDIA GAMES STORE</h5>
              <Link
                type="button"
                className="btn btn-dark"
                data-bs-toggle="modal"
                to="#exampleModalToggle"
                role="button"
              >
                Add New Store
              </Link>
            </div>
            <div className="row">
              {allData?.map((val, ind) => {
                return (
                  <div key={ind} className="col-lg-3 mb-4">
                    <div className="team-card">
                      <img src={`${UPLOADSDATAS}/${val.image}`} alt="" />
                      <div className="d-flex align-items-center justify-content-between p-2 pt-0 pb-0">
                        <div className="team-content">
                          <h5>{val.name}</h5>
                          <h6>
                            <Link to={val.link} target="_blank">
                              Check Campaign Link
                            </Link>
                          </h6>
                        </div>
                        <div className="delete-material">
                          <button
                            type="button"
                            onClick={() => DeleteHandler(val._id)}
                          >
                            <FiTrash2 />
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <AddStore />
    </>
  );
}

export default Banner;
