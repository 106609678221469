import React, { useEffect } from "react";
import Nav from "../../Components/Mediaxpedia/Nav";
import TopNavbar from "../../Components/Mediaxpedia/TopNavbar";
import { Link } from "react-router-dom";
import { useGetCareerQuery } from "../../App/Slice/careerSlice";
import { useGetContactQuery } from "../../App/Slice/contactSlice";
import { useGetLifeQuery } from "../../App/Slice/lifeSlice";
import { useGetTeamQuery } from "../../App/Slice/teamSlice";
import { useGetUsersQuery } from "../../App/Slice/UserSlice";
import { APIURL, Auth_user, GETTOKEN } from "../../Secure/Helper";

function Media_Xpedia() {
  const { data: careerData } = useGetCareerQuery();
  const { data: contactData } = useGetContactQuery();
  const { data: lifeData } = useGetLifeQuery();
  const { data: ourTeam } = useGetTeamQuery();
  const { data: userData, isError } = useGetUsersQuery();
  // console.log(userData.message);

  const getData = () => {
    try {
      fetch(`${APIURL}/user/all-admin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let resut_data = result.message;
          if (resut_data === "Not Authorized") {
            localStorage.clear();
            window.location.href = "/signin";
          } else {
            return;
          }
        });
    } catch (error) {
      if (error) {
        localStorage.clear();
        window.location.href = "/signin";
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (Auth_user === "admin") {
      return;
    } else {
      localStorage.clear();
    }
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <TopNavbar />
        </div>
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 bg-light p-0">
            <Nav />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <div className="media-grid">
              <Link to="/admin-users">
                <div className="grid-items">
                  <h4>Admin - {userData?.user_length}</h4>
                </div>
              </Link>
              <Link to="/career">
                <div className="grid-items">
                  <h4>Careers - {careerData?.career_length}</h4>
                </div>
              </Link>
              <Link to="/contact">
                <div className="grid-items">
                  <h4>contact - {contactData?.contact_length}</h4>
                </div>
              </Link>
              <Link to="/our-team">
                <div className="grid-items">
                  <h4>Our team - {ourTeam?.team_length}</h4>
                </div>
              </Link>
              <Link to="/mediaxpedia-life">
                <div className="grid-items">
                  <h4>Gallery - {lifeData?.life_length}</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Media_Xpedia;
