import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { Auth_user } from "../Secure/Helper";

function Navbar() {
  const nevigate = useNavigate();
  let user = JSON.parse(localStorage.getItem("admin-user"));
  // console.log(user);
  const LogoutFunction = () => {
    localStorage.clear();
    nevigate("/signin");
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <Link className="navbar-brand" to="/">
            <img src="../assets/image/mediaxpedia.png" alt="mediaxpedia" />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
              <li className="nav-item">
                <div className="btn-group">
                  <button
                    type="button"
                    className="btn btn-secondary dropdown-toggle"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <samp className="navbar-username">
                      {user && user.name?.slice(0, 1)}
                    </samp>
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end">
                    <li>
                      <Link className="dropdown-item" to="#">
                        ✋ {user && user.name}
                      </Link>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        Settings
                      </a>
                    </li>
                    {Auth_user === "admin" && (
                      <li>
                        <Link className="dropdown-item" to="/signup">
                          Register New Admin
                        </Link>
                      </li>
                    )}
                    <li className="mt-2">
                      <button
                        className="dropdown-item bg-danger"
                        type="button"
                        onClick={LogoutFunction}
                      >
                        Logout
                      </button>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}

export default Navbar;
