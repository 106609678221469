import React from "react";
import Nav from "../../../Components/Mediaxpedia/Nav";
import TopNavbar from "../../../Components/Mediaxpedia/TopNavbar";
import { Link } from "react-router-dom";
import {
  useDeleteCareerMutation,
  useGetCareerQuery,
} from "../../../App/Slice/careerSlice";
import { FiChevronRight, FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import { UPLOADSDATAS } from "../../../Secure/Helper";

function AllCareer() {
  const { data } = useGetCareerQuery();
  const all_data = data?.career;

  const [deleteCareer] = useDeleteCareerMutation();

  const filePath = `${UPLOADSDATAS}`;

  const DeleteHandler = async (id) => {
    if (id) {
      const result = await deleteCareer(id);
      console.log(result);
      let mes = result?.data?.status;
      if (mes === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.href = "/career";
        }, 2000);
      } else {
        toast.error(`${mes}`, {
          position: "top-right",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      return;
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <TopNavbar />
        </div>
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 bg-light p-0">
            <Nav />
          </div>
          <div className="col-lg-10 col-md-10 col-12 scroll-section">
            <div className="heading-section mt-3 pannel-btn d-flex align-items-center justify-content-between">
              <h5 className="mb-0 fw-bold">ALL CAREER</h5>
            </div>
            <table className="table mt-3">
              <thead>
                <tr className="bg-light">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Phone</th>
                  <th scope="col">Designation / Specialization</th>
                  <th scope="col">Date</th>
                  <th scope="col">BIO</th>
                  <th scope="col">Resume</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {all_data?.map((val, ind) => {
                  const {
                    _id,
                    name,
                    email,
                    designation,
                    specialization,
                    phone,
                    resume,
                    description,
                    createdAt,
                  } = val;
                  return (
                    <tr key={ind}>
                      <th scope="row">
                        <FiChevronRight />
                      </th>
                      <td>{name}</td>
                      <td>{email}</td>
                      <td>{phone}</td>
                      <td className="bg-light">
                        {designation} - {specialization}
                      </td>
                      <td>{createdAt?.slice(0, 10)}</td>
                      <td>
                        <p>{description}</p>
                      </td>
                      <td>
                        <Link
                          to={`${filePath}/${resume}`}
                          download={resume}
                          type="button"
                          className="btn btn-dark"
                          title={resume}
                          target="_blank"
                        >
                          View
                        </Link>
                      </td>
                      <td
                        title={`${name} Delete!`}
                        type="button"
                        className="career_delete bg-dark"
                        onClick={() => DeleteHandler(_id)}
                      >
                        <FiTrash2 />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      <div
        className="modal fade"
        id="exampleModalToggle"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel">
                Modal 1
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Show a second modal and hide this one with the button below.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                data-bs-target="#exampleModalToggle2"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Open second modal
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="exampleModalToggle2"
        aria-hidden="true"
        aria-labelledby="exampleModalToggleLabel2"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalToggleLabel2">
                Modal 2
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              Hide this modal and show the first with the button below.
            </div>
            <div className="modal-footer">
              <button
                className="btn btn-primary"
                data-bs-target="#exampleModalToggle"
                data-bs-toggle="modal"
                data-bs-dismiss="modal"
              >
                Back to first
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllCareer;
