import React, { useEffect } from "react";
import Nav from "../../Components/Xpediagames/Nav";
import TopNavbar from "../../Components/Xpediagames/TopNavbar";
import { Link } from "react-router-dom";
import { useGetUsersQuery } from "../../App/Slice/UserSlice";
import { APIURL, GETTOKEN } from "../../Secure/Helper";
import { useGetBannerQuery } from "../../App/Slice/XpediaGames/bannerSlice";
import { useGetXStoreQuery } from "../../App/Slice/XpediaGames/xstoreSlice";
import { useGetGamesQuery } from "../../App/Slice/XpediaGames/gamesSlice";
import { useGetXContactQuery } from "../../App/Slice/XpediaGames/xcontactSlice";

function XpediaGames() {
  const { data: bannerData } = useGetBannerQuery();
  const { data: contactData } = useGetXContactQuery();
  const { data: storeData } = useGetXStoreQuery();
  const { data: gamesData } = useGetGamesQuery();
  const { data: userData, isError } = useGetUsersQuery();
  // console.log(bannerData);

  const getData = () => {
    try {
      fetch(`${APIURL}/user/all-admin`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${GETTOKEN()}`,
        },
      })
        .then((res) => res.json())
        .then((result) => {
          let resut_data = result.message;
          if (resut_data === "Not Authorized") {
            localStorage.clear();
            window.location.href = "/signin";
          } else {
            return;
          }
        });
    } catch (error) {
      if (error) {
        localStorage.clear();
        window.location.href = "/signin";
      } else {
        return;
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row">
          <TopNavbar />
        </div>
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 bg-light p-0">
            <Nav />
          </div>
          <div className="col-lg-10 col-md-10 col-12">
            <div className="media-grid">
              <Link to="/admin-users">
                <div className="grid-items">
                  <h4>Admin - {userData?.user_length}</h4>
                </div>
              </Link>
              <Link to="/xpediagame/banner">
                <div className="grid-items">
                  <h4>Banner - {bannerData?.slider_length}</h4>
                </div>
              </Link>
              <Link to="/xpediagame/contact">
                <div className="grid-items">
                  <h4>contact - {contactData?.contact_length}</h4>
                </div>
              </Link>
              <Link to="/xpediagame/store">
                <div className="grid-items">
                  <h4>Games Store - {storeData?.store_length}</h4>
                </div>
              </Link>
              <Link to="/xpediagame/games">
                <div className="grid-items">
                  <h4>Games List - {gamesData?.games_length}</h4>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default XpediaGames;
