import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <>
      <div className="side-navbar">
        <ul>
          <li>
            <Link to="/xpediagames-details">Dashboard</Link>
          </li>
          <li>
            <Link to="/xpediagame/banner">banner</Link>
          </li>
          <li>
            <Link to="/xpediagame/contact">Contact</Link>
          </li>
          <li>
            <Link to="/xpediagame/games">Our Games</Link>
          </li>
          <li>
            <Link to="/xpediagame/store">Our Store</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Nav;
