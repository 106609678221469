import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIURLX, GETTOKEN } from "../../../Secure/Helper";

export const games = createApi({
  reducerPath: "games",
  baseQuery: fetchBaseQuery({ baseUrl: `${APIURLX}` }),
  endpoints: (builder) => ({
    getGames: builder.query({
      query: () => {
        // console.log("body data :", body);
        return {
          url: "/games",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteGames: builder.mutation({
      query: (id) => {
        // console.log("body data :", body);
        return {
          url: `/games/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createGames: builder.mutation({
      query: (body) => {
        // console.log("body data :", body);
        return {
          url: "games",
          method: "POST",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
          body: body,
        };
      },
    }),
  }),
});

export const { useGetGamesQuery, useDeleteGamesMutation, useCreateGamesMutation } = games;
