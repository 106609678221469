import React, { useState } from "react";
import { Link } from "react-router-dom";

function ForgotPassword() {
    const [email, setEmail] = useState()
  const forgotPasswordSubmit = () => {
    alert("OK");
  };
  return (
    <>
      <div className="container-fluid p-0 forgot-password-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-12 p-0">
            <div className="login-form">
              <form onSubmit={forgotPasswordSubmit}>
                <div className="input-fields text-center mb-4">
                  <h5 className="w-100">Forgot Your Password</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores, suscipit.
                  </p>
                </div>
                <div className="input-fields">
                  <label>Enter Your E-mail</label>
                  <input
                    type="text"
                    placeholder="abc@gmail.com"
                    onClick={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="forgot-password d-flex justify-content-end mb-2">
                  <p>I have Already accound. <Link to="/signin">Sign In</Link> </p>
                </div>
                <div className="input-fields submit-button">
                  <button className="btn btn-dark" type="submit">
                    FORGOT PASSWORD
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ForgotPassword;
