import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIURLX, GETTOKEN } from "../../../Secure/Helper";

export const Xstore = createApi({
  reducerPath: "Xstore",
  baseQuery: fetchBaseQuery({ baseUrl: `${APIURLX}` }),
  endpoints: (builder) => ({
    getXStore: builder.query({
      query: () => {
        // console.log("body data :", body);
        return {
          url: "/store",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteXStore: builder.mutation({
      query: (id) => {
        // console.log("body data :", body);
        return {
          url: `/store/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createXStore: builder.mutation({
      query: (body) => {
        // console.log("body data :", body);
        return {
          url: "/store",
          method: "POST",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
          body: body,
        };
      },
    }),
  }),
});

export const {
  useGetXStoreQuery,
  useDeleteXStoreMutation,
  useCreateXStoreMutation,
} = Xstore;
