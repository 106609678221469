import React from "react";
import { Link } from "react-router-dom";

function TopNavbar() {
  return (
    <>
      <div className="col-12 mx-auto">
        <nav className="navbar navbar-light bg-light">
          <div className="container-fluid p-0">
            <Link to="/mediaxpedia-details" className="navbar-brand">
              <img src="../assets/image/mediaxpedia.png" alt="" />
            </Link>
            <div className="pannel-btn me-3">
              <Link to="/" type="button" className="btn btn-dark">
                Go Panel
              </Link>
            </div>
          </div>
        </nav>
      </div>
    </>
  );
}

export default TopNavbar;
