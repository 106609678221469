import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIURL, GETTOKEN } from "../../Secure/Helper";

export const users = createApi({
  reducerPath: "users",
  baseQuery: fetchBaseQuery({ baseUrl: `${APIURL}/user` }),
  endpoints: (builder) => ({
    createUsers: builder.mutation({
      query: (body) => {
        // console.log("body data :", body);
        return {
          url: "/signin",
          method: "POST",
          body: body,
        };
      },
    }),

    getUsers: builder.query({
      query: (body) => {
        // console.log("body data :", body);
        return {
          url: "/all-admin",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
          body: body,
        };
      },
    }),

    deleteUsers: builder.mutation({
      query: (id) => {
        // console.log("body data :", body);
        return {
          url: `/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    signUpUsers: builder.mutation({
      query: (body) => {
        console.log("body data :", body);
        return {
          url: "/signup",
          method: "POST",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
          body: body,
        };
      },
    }),
    
  }),
});

export const {
  useCreateUsersMutation,
  useGetUsersQuery,
  useDeleteUsersMutation,
  useSignUpUsersMutation
} = users;
