import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useCreateUsersMutation } from "../App/Slice/UserSlice";
import { toast } from "react-toastify";

function SignIn() {
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();

  const [createUsers] = useCreateUsersMutation();

  const nevigate = useNavigate();
  let storage = JSON.parse(localStorage.getItem("admin-user"));

  useEffect(() => {
    if (storage) {
      return nevigate("/");
    } else {
      return;
    }
  }, []);

  const signInFormSubmit = async (e) => {
    e.preventDefault();
    const data = { email: email, password: password };
    if (
      email === "" ||
      (email === "undefined" && password === "") ||
      password === "undefined"
    ) {
      return alert("all fields are required!");
    }
    const result = await createUsers(data);
    // const mes = result?.data?.status;
    const message = result?.data?.message;
    const user = result?.data?.user;
    // console.log("users :", user);
    if (!user) {
      toast.error(`${message}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else if (user.role === "normal" || user.email_verified === "false") {
      toast.error("Only login for Verify Admin user!", {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return;
    } else {
      toast.success(`Hey ${user.name} ${message}`, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });

      localStorage.setItem("admin-user", JSON.stringify(user));
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    }
  };

  return (
    <>
      <div className="container-fluid p-0 form-bg-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-12 p-0">
            <div className="login-form">
              <form onSubmit={signInFormSubmit}>
                <div className="input-fields text-center mb-4">
                  <h5 className="w-100">Login</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores, suscipit.
                  </p>
                </div>
                <div className="input-fields">
                  <label>E-mail</label>
                  <input
                    type="text"
                    placeholder="abc@gmail.com" 
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-fields mb-4">
                  <label>Password</label>
                  <input
                    type="password"
                    placeholder="********"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields submit-button">
                  <button className="btn btn-dark" type="submit">
                    SIGN IN
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignIn;
