import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIURLX, GETTOKEN } from "../../../Secure/Helper";

export const banner = createApi({
  reducerPath: "banner",
  baseQuery: fetchBaseQuery({ baseUrl: `${APIURLX}` }),
  endpoints: (builder) => ({
    getBanner: builder.query({
      query: () => {
        // console.log("body data :", body);
        return {
          url: "/banner",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteBanner: builder.mutation({
      query: (id) => {
        // console.log("body data :", body);
        return {
          url: `/banner/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    createBanner: builder.mutation({
      query: (body) => {
        console.log("body data :", body);
        return {
          url: "banner",
          method: "POST",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
          body: body,
        };
      },
    }),
  }),
});

export const { useGetBannerQuery, useDeleteBannerMutation, useCreateBannerMutation } = banner;
