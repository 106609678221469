import React from "react";
import Navbar from "../Components/Navbar";
import { Link } from "react-router-dom";
import web from "../JSON/web_list.json";

function Home() {
  return (
    <>
      <Navbar />
      <div className="container">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-12">
            <div className="website-grid">
              {web?.map((val, ind) => {
                const { id, logo, url, name } = val;
                return (
                  <Link key={ind} to={url} className="second-grid">
                    <div className="enter-website">
                      <div className="website-logo">
                        <img src={logo} alt="mediaXpedia" />
                      </div>
                      <div className="website-name">
                        <h5 className="text-center"> {name}</h5>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
