import React from "react";
import Nav from "../../../Components/Xpediagames/Nav";
import TopNavbar from "../../../Components/Xpediagames/TopNavbar";
import { FiChevronRight, FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import {
  useDeleteXContactMutation,
  useGetXContactQuery,
} from "../../../App/Slice/XpediaGames/xcontactSlice";

function AllContact() {
  const { data } = useGetXContactQuery();
  const all_data = data?.contact;
  const [deleteContact] = useDeleteXContactMutation();

  const DeleteHandler = async (id) => {
    if (id) {
      const result = await deleteContact(id);
      // console.log(result);
      let mes = result?.data?.status;
      if (mes === "success") {
        toast.success(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
        setTimeout(() => {
          window.location.href = "/xpediagame/contact";
        }, 1000);
      } else {
        toast.error(`${mes}`, {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "dark",
        });
      }
    } else {
      return;
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <TopNavbar />
        </div>
        <div className="row m-0">
          <div className="col-lg-2 col-md-2 col-12 bg-light p-0">
            <Nav />
          </div>
          <div className="col-lg-10 col-md-10 col-12 scroll-section">
            <div className="heading-section mt-3 pannel-btn d-flex align-items-center justify-content-between">
              <h5 className="mb-0 fw-bold">ALL CONTACT</h5>
            </div>
            <table className="table mt-3">
              <thead>
                <tr className="bg-light">
                  <th scope="col">#</th>
                  <th scope="col">Name</th>
                  <th scope="col">Email</th>
                  <th scope="col">Subject</th>
                  <th scope="col">Message</th>
                  <th scope="col">Date</th>
                  <th scope="col">Delete</th>
                </tr>
              </thead>
              <tbody>
                {all_data?.map((val, ind) => {
                  const { _id, name, email, subject, message, createdAt } = val;
                  return (
                    <tr key={ind}>
                      <th scope="row">
                        <FiChevronRight />
                      </th>
                      <td>{name}</td>
                      <td>{email}</td>
                      <td>{subject}</td>
                      <td>
                        <p>{message}</p>
                      </td>
                      <td>{createdAt?.slice(0, 10)}</td>
                      <td
                        title={`${name} Delete!`}
                        type="button"
                        className="career_delete bg-dark"
                        onClick={() => DeleteHandler(_id)}
                      >
                        <FiTrash2 />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
}

export default AllContact;
