import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query";
import { users } from "./Slice/UserSlice";
import { career } from "./Slice/careerSlice";
import { contact } from "./Slice/contactSlice";
import { team } from "./Slice/teamSlice";
import { life } from "./Slice/lifeSlice";
import { banner } from "./Slice/XpediaGames/bannerSlice";
import { games } from "./Slice/XpediaGames/gamesSlice";
import { Xstore } from "./Slice/XpediaGames/xstoreSlice";
import { Xcontact } from "./Slice/XpediaGames/xcontactSlice";

export const store = configureStore({
  reducer: {
    [users.reducerPath]: users.reducer,
    [career.reducerPath]: career.reducer,
    [contact.reducerPath]: contact.reducer,
    [team.reducerPath]: team.reducer,
    [life.reducerPath]: life.reducer,
    [banner.reducerPath]: banner.reducer,
    [games.reducerPath]: games.reducer,
    [Xstore.reducerPath]: Xstore.reducer,
    [Xcontact.reducerPath]: Xcontact.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat([
      users.middleware,
      career.middleware,
      contact.middleware,
      team.middleware,
      life.middleware,
      banner.middleware,
      games.middleware,
      Xstore.middleware,
      Xcontact.middleware,
    ]),
});
setupListeners(store.dispatch);
