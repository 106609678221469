import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useSignUpUsersMutation } from "../App/Slice/UserSlice";
import { APIURL, GETTOKEN } from "../Secure/Helper";

function SignUp() {
  const [name, setName] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [password_confirmation, setConformPassword] = useState();
  const [loading, setLoading] = useState(false);

  const nevigate = useNavigate();
  const [signUpCreate] = useSignUpUsersMutation();

  // console.log("token :", GETTOKEN());

  const RegisterHandler = (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const AllData = {
        name: name,
        email: email,
        password: password,
        password_confirmation: password_confirmation,
      };
      if (!name || !email || !password || !password_confirmation) {
        toast.error("all fields are required...", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        return;
      } else if (password !== password_confirmation) {
        toast.error("Password and Confirm Password doesn't match", {
          position: "top-right",
          autoClose: 1000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setTimeout(() => {
          setLoading(false);
        }, 2000);
        return;
      } else {
        fetch(`${APIURL}/user/signup`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${GETTOKEN()}`,
          },
          body: JSON.stringify(AllData),
        })
          .then((res) => res.json())
          .then((result) => {
            // console.log(result);
            let allResult = result.user;
            if (!allResult) {
              toast.error(result.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              setTimeout(() => {
                setLoading(false);
              }, 2000);
              return;
            } else {
              // localStorage.setItem("user_data", JSON.stringify(allResult));
              toast.success(result.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });

              setTimeout(() => {
                nevigate("/");
              }, 3000);
            }
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } catch (error) {
      toast.error(error.message, {
        position: "top-right",
        autoClose: 1000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };

  return (
    <>
      <div className="container-fluid p-0 form-bg-section">
        <div className="row m-0">
          <div className="col-lg-12 col-md-12 col-12 p-0">
            <div className="login-form">
              <form onSubmit={RegisterHandler}>
                <div className="input-fields text-center mb-5">
                  <h5 className="w-100">Sign Up</h5>
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Maiores, suscipit.
                  </p>
                </div>
                <div className="input-fields">
                  <label>Full Name</label>
                  <input
                    type="text"
                    placeholder="Media Xpedia"
                    onChange={(e) => setName(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Enter E-mail</label>
                  <input
                    type="text"
                    placeholder="abc@gmail.com"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Password</label>
                  <input
                    type="text"
                    placeholder="********"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields">
                  <label>Conform Password</label>
                  <input
                    type="password"
                    placeholder="********"
                    onChange={(e) => setConformPassword(e.target.value)}
                  />
                </div>
                <div className="input-fields submit-button">
                  <button
                    className="btn btn-dark"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? "Please wait..." : "Sign Up"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SignUp;
