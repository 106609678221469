import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { APIURL, GETTOKEN } from "../../Secure/Helper";

export const career = createApi({
  reducerPath: "career",
  baseQuery: fetchBaseQuery({ baseUrl: `${APIURL}` }),
  endpoints: (builder) => ({
    getCareer: builder.query({
      query: () => {
        // console.log("body data :", body);
        return {
          url: "/career",
          method: "GET",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),

    deleteCareer: builder.mutation({
      query: (id) => {
        // console.log("body data :", body);
        return {
          url: `/career/${id}`,
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${GETTOKEN()}`,
          },
        };
      },
    }),
  }),
});

export const { useGetCareerQuery, useDeleteCareerMutation } = career;
