import React from "react";
import { Link } from "react-router-dom";

function Nav() {
  return (
    <>
      <div className="side-navbar">
        <ul>
          <li>
            <Link to="/mediaxpedia-details">Dashboard</Link>
          </li>
          <li>
            <Link to="/career">Career</Link>
          </li>
          <li>
            <Link to="/contact">Contact</Link>
          </li>
          <li>
            <Link to="/our-team">Our Team</Link>
          </li>
          <li>
            <Link to="/mediaxpedia-life">Xpedia Life</Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Nav;
